import React from "react";
import * as S from "./styled";
import { Badge } from "../../components";

interface CardArticleProps {
  image: string;
  title: string;
  description: string;
  link: string;
  hashtag: string[];
}

const CardArticle: React.FC<CardArticleProps> = ({
  image,
  title,
  description,
  link,
  hashtag,
}) => {
  return (
    <S.Container>
      <S.Link href={link} target="_blank">
        <S.Wrapper>
          <S.ContainerText>
            <S.Title>{title}</S.Title>
            <S.Description>{description}</S.Description>
            {hashtag.map((item, key) => (
              <Badge key={key} label={item} circle />
            ))}
          </S.ContainerText>
          <S.Image src={image} alt={"Logo do artigo"} />
        </S.Wrapper>
      </S.Link>
    </S.Container>
  );
};

export default CardArticle;
