import styled from "styled-components";
import { darken } from "polished";

interface Props {
  bold?: boolean;
}

export const ContainerBadge = styled.div`
  position: absolute;
  font-size: 2rem;
  right: 10px;
`;

export const Container = styled.div`
  position: relative;
  color: white;
  border-radius: 20px;
  width: 300px;
  height: 100%;
  background-color: ${(props) => props.theme.colors.backgroundCard};
  box-shadow: 0 8px 32px 0 ${(props) => props.theme.colors.dark};;
  margin: 20px;
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    background-color: ${(props) => darken(0.2, props.theme.colors.secondary)};
    box-shadow: 0 0 5px ${(props) =>
      props.theme.colors.secondary};, 0 0 25px ${(props) =>
  props.theme.colors.secondary}, 0 0 50px ${(props) =>
  props.theme.colors.secondary},
      0 0 100px ${(props) => props.theme.colors.secondary};
  }
`;

export const Image = styled.img<{ mobile?: boolean}>`
  width: 100%;
  height: ${props => props.mobile ? 'auto' : '180px'};
  border-radius: 20px 20px 0 0;
`;

export const ContainerText = styled.div`
  padding: 15px;
`;

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.title};
  font-size: 20px;
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 10px;
`;

export const ContainerLink = styled.div`
margin-top: 30px;
  margin-bottom: 50px;
`;

export const Description = styled.p<Props>`
  font-family: ${(props) => props.theme.fonts.title};
  font-weight: ${(props) => (props.bold ? "bold" : "300")};
  color: ${(props) => props.theme.colors.text};
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Link = styled.a`
  font-family: ${(props) => props.theme.fonts.title};
  font-weight: 200;
  text-decoration: none;
  cursor: pointer;
  color: ${(props) => props.theme.colors.text};
  &:hover {
    color: ${(props) => props.theme.colors.quartenary};
    font-weight: 400;
  }
`;

export const ContainerIcon = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  margin-top: 10px;
  font-size: 2rem;
`;

export const Icon = styled.div`
  margin-right: 10px;
`;
