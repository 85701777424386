import {
  faInstagram,
  faGithub,
  faDev,
  faLinkedin,
  faMedium,
  faNpm,
  faFacebook,
  faQuora,
  faTwitter,
  faCodepen
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faHeading } from "@fortawesome/free-solid-svg-icons";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FaHtml5, FaCss3Alt, FaReact, FaDocker, FaAws } from "react-icons/fa";
import {
  SiJavascript,
  SiTypescript,
  SiRedux,
  SiMongodb,
  SiNextdotjs,
  SiStyledcomponents,
  SiJest,
  SiAxios,
  SiExpress,
} from "react-icons/si";
import { DiNodejs } from "react-icons/di";
import { IconType } from "react-icons";

export interface Project {
  image: string;
  title: string;
  description: string;
  technologys: string[];
  type: string;
  link: string;
  github?: string;
  npm?: string;
}

export interface SocialMedia {
  link: string;
  name: IconDefinition;
}

export interface Jobs {
  jobPosition: string;
  companyTime: string;
  company: string;
  jobDescription: string[];
}

export interface Courses {
  institution: string;
  graduation: string;
  timeTaken: string;
  type: string;
}

export interface Skills {
  name: string;
  icon: any /* IconType; */;
  description: string;
  link: string;
  progress: number;
}

export interface Certificates {
  title: string;
  company: string;
  date: string;
  image: string;
  link: string;
}

export interface Awards {
  description: string;
}

export interface LinksNames {
  link: string;
  name: string;
}

export interface SoftSkills {
  name: string;
  value: number;
}

export const projects: Project[] = [
  {
    image:
      "https://raw.githubusercontent.com/HMontarroyos/FeboUI/main/public/febo_ui_logo.png",
    title: "FeboUI",
    description:
      "A FeboUI é uma biblioteca de componentes para React. que desenvolvi com o intuito de simplificar a vida dos desenvolvedores na hora de criar seus componentes em sua aplicação de forma eficaz e descomplicada, sendo um projeto totalmente open source, o que ajuda para quem quiser contribuir para que ela cresça ainda mais.",
    technologys: [
      "React",
      "Styled Components",
      "Polished",
      "Typescript",
      "Babel",
      "Rollup JS",
    ],
    type: "front-end",
    link: "https://www.feboui.com.br/",
    github: "https://github.com/HMontarroyos/FeboUI",
    npm: "https://www.npmjs.com/package/febo-ui",
  },
  {
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjqZ4BYy2_DYfCPr4qPS6PlR-EpKkPlTNJPLBkNyBuuESXik1F3onOx7dcTQA4-hP3JaE&usqp=CAU",
    title: "Ame Shopping",
    description:
      "Participei do desenvolvimento do Ame Shopping, um mini-app integrado ao super app da Ame. Ele oferece condições de compra com cashback exclusivos, permitindo ao usuário adquirir produtos em várias lojas do grupo Americanas sem sair do app. O Projeto, além do mini-app, consta com um CMS feito em React para cadastro de produtos, e sua API construída em NodeJS. ",
    technologys: ["React", "Node JS", "TypeScript"],
    type: "full-stack",
    link: "https://www.amedigital.com/blog/conheca-o-ame-shopping-a-sua-nova-forma-de-comprar-online",
  },
  {
    image:
      "https://camo.githubusercontent.com/e42e2fe3f1a3a85443cf4b6d2bc178799d5c3cd0299fb633d6db4f2f3a2882aa/68747470733a2f2f75706c6f61646465696d6167656e732e636f6d2e62722f696d616765732f3030342f3533302f3733302f66756c6c2f53746172576172735f486f6d652e706e673f31363838343736343339",
    title: "Star Wars Texts Introduction",
    description:
      "Este Projeto visa exibir todas as intros texts dos filmes de Star Wars live action lançados até então, onde o usuário ao clicar em cada pôster respectivo é encaminhado para uma nova tela aonde verá a intro do filme em questão.",
    technologys: ["React", "TypeScript", "Styled-Components"],
    type: "front-end",
    link: "https://star-wars-introduction.vercel.app/",
    github: "https://github.com/HMontarroyos/star_wars_introduction",
  },
  {
    image:
      "https://user-images.githubusercontent.com/60220406/106364330-fa001980-630c-11eb-8d35-f601789b89c4.png",
    title: "Quiz de JavaScript",
    description:
      "Consiste em uma aplicação de quiz onde o usuário vai testar seus conhecimentos na linguagem javascript, e no final terá sua pontuação, ainda tendo veiculação com outros quizes de terceiros da comunidade da imersão alura.",
    technologys: [
      "React",
      "Next",
      "Styled-Components",
      "Frame Motion",
      "Post CSS",
    ],
    type: "front-end",
    link: "https://aluraquiz-javascript-hmontarroyos.vercel.app/",
    github: "https://github.com/HMontarroyos/quiz_JavaScript",
  },
  {
    image:
      "https://user-images.githubusercontent.com/60220406/107148450-d737bb80-6931-11eb-83d5-e515927bb76e.png",
    title: "Dev.Finance $",
    description:
      "Essa aplicação de controle financeiro, é capaz de medir e gerenciar seus gastos mensais com praticidade e eficiência.",
    technologys: ["Html", "JavaScript", "CSS", "Font Awesome"],
    type: "front-end",
    link: "https://dev-finance-hmontarroyos.vercel.app/",
    github: "https://github.com/HMontarroyos/dev_finance",
  },
  {
    image:
      "https://camo.githubusercontent.com/63e9c275b6709ff542be2b9fed0ea18d504ab627b6760f14ae26da5c195d9ea7/68747470733a2f2f75706c6f61646465696d6167656e732e636f6d2e62722f696d616765732f3030342f3438352f3133332f66756c6c2f686f6d655f73656d5f746f646f2e706e673f31363835323837363334",
    title: "ToDo List",
    description:
      "Esta é uma aplicação onde o usuário pode criar toDos compartilhar e excluir as tarefas que já foram feitas por ele.",
    technologys: [
      "React",
      "Typescript",
      "Styled Components",
      "Polished",
      "React Reveal",
      "React Toastify",
      "Sweet Alert2",
    ],
    type: "front-end",
    link: "https://to-do-list-mu-brown.vercel.app/",
    github: "https://github.com/HMontarroyos/to_do_list",
  },
  {
    image:
      "https://user-images.githubusercontent.com/60220406/109581128-9ec37180-7ada-11eb-808a-6aed9c52fb8a.png",
    title: "Pomodoro",
    description:
      "Este Projeto é uma aplicação focada no método pomodoro, gamificada onde permite ao usuário receber pontos de experiência (XP) a cada tarefa concluída, permitindo que o mesmo suba de nível.",
    technologys: ["React", "Next", "Typescript"],
    type: "front-end",
    link: "https://pomodoro-git-main-hmontarroyos.vercel.app/",
    github: "https://github.com/HMontarroyos/pomodoro",
  },
  {
    image:
      "https://user-images.githubusercontent.com/60220406/138523571-565b2f5c-9c5f-4c61-af41-733fd55fe05b.gif",
    title: "Restaurant Finder",
    description:
      "Este consiste em uma aplicação onde o usuário habilita sua localização e digita um estabelecimento ou determinada comida e o mesmo lista os restaurantes próximo a sua localidade passada.",
    technologys: [
      "React",
      "Styled Components",
      "Redux",
      "Lottie Files",
      "React Slick",
      "React Rating Stars",
      "Styled Reset",
      "Material UI",
      "Google Maps React",
      "Netlify",
      "ESlint",
      "Editor Config",
    ],
    type: "front-end",
    link: "https://restaurant-food-search.netlify.app/",
    github: "https://github.com/HMontarroyos/restaurant_search_Google",
  },
  {
    image:
      "https://user-images.githubusercontent.com/60220406/102154354-f34e3d80-3e57-11eb-834e-0d5198cdcf59.png",
    title: "Curriculum",
    description:
      "Esta Aplicação se trata de um curriculum, meu desenvolvido, por um template baseado no projeto do anubhavsrivastava, feito através do gatsby.",
    technologys: ["Gatsby"],
    type: "front-end",
    link: "https://portfolio-seven-beige.vercel.app/",
    github: "https://github.com/HMontarroyos/curriculum",
  },
  {
    image:
      "https://user-images.githubusercontent.com/60220406/270797611-fd55a79c-5334-40fa-903b-9f960e7eeb49.jpg",
    title: "To-Do",
    description:
      "Essa aplicação consiste em um To-Do feito para o mobile, você consegue criar uma task e após isso pode marcar ela como resolvida ou excluí-la ao acabar a mesma. De forma totalmente intuitiva, tendo ainda uma página de introdução que explica como criar cada detalhe, elá também consiste em um switch onde é possível alterar entre o tema Dark Mode ou Light Mode na aplicação, facilitando para o gosto de cada usuário.",
    technologys: [
      "React Native",
      "Expo",
      "TypeScript",
      "Styled Components",
      "Lottie React Native",
      "React Navigation",
    ],
    type: "mobile",
    link: "https://drive.google.com/file/d/16uNOXyLjpI_8bNrRxbQPg77EUdqP6P6l/view",
    github: "https://github.com/HMontarroyos/to_do",
  },
  {
    image:
      "https://user-images.githubusercontent.com/60220406/271836399-32312ee2-d054-47fd-b5fb-aa469de0641c.png",
    title: "ForTech",
    description:
      "Esse Projeto se trata de uma loja de customização de computadores online. A ideia é permitir que os clientes personalizem o seu próprio computador, escolhendo entre diversas opções de hardware. Cada componente do computador tem suas regras específicas de compatibilidade com outros componentes.",
    technologys: [
      "React",
      "Typescript",
      "Styled Components",
      "Polished",
      "React Icons",
      "React Lottie",
      "Sweet Alert2",
    ],
    type: "front-end",
    link: "https://for-tech.vercel.app/",
    github: "https://github.com/HMontarroyos/ForTech",
  },
  {
    image:
      "https://user-images.githubusercontent.com/60220406/287751106-73f9bdab-50a9-43c0-a7f8-b026ab8242a8.png?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJnaXRodWIuY29tIiwiYXVkIjoicmF3LmdpdGh1YnVzZXJjb250ZW50LmNvbSIsImtleSI6ImtleTEiLCJleHAiOjE3MDE3MTAyNjUsIm5iZiI6MTcwMTcwOTk2NSwicGF0aCI6Ii82MDIyMDQwNi8yODc3NTExMDYtNzNmOWJkYWItNTBhOS00M2MwLWE3ZjgtYjAyNmFiODI0MmE4LnBuZz9YLUFtei1BbGdvcml0aG09QVdTNC1ITUFDLVNIQTI1NiZYLUFtei1DcmVkZW50aWFsPUFLSUFJV05KWUFYNENTVkVINTNBJTJGMjAyMzEyMDQlMkZ1cy1lYXN0LTElMkZzMyUyRmF3czRfcmVxdWVzdCZYLUFtei1EYXRlPTIwMjMxMjA0VDE3MTI0NVomWC1BbXotRXhwaXJlcz0zMDAmWC1BbXotU2lnbmF0dXJlPTNiYmEwNWExZGI5NjRhZDc0NWIxYjAwYmJmMmMzMmVjNGEyZDI5YjY1MjZkZDYwMzQxNWQ4YWMxMWU5MDFlY2UmWC1BbXotU2lnbmVkSGVhZGVycz1ob3N0JmFjdG9yX2lkPTAma2V5X2lkPTAmcmVwb19pZD0wIn0.3bMG4weygtdlGI1Z6_OTJF9o_FNfV-RIDBRmDNNw5fE",
    title: "Segura Rio",
    description:
      "A Aplicação em questão se trata de uma seguradora onde informa os tipos de produtos que oferta para seu cliente, e caso o usuário se interesse pode preencher o formulário e enviar os dados para ela entrar em contato. Construído com micro front-end através da lib single-spa em React",
    technologys: [
      "React",
      "Styled Components",
      "Polished",
      "React Icons",
      "Axios",
      "Single Spa",
      "React Toastify",
      "React Cookie Consent",
      "React Reveal",
      "Microsoft Clarity",
      "Mocky IO",
      "Digital Ocean",
    ],
    type: "front-end",
    link: "https://segurario.netlify.app/",
    github: "https://github.com/HMontarroyos/Segura_Rio",
  },
  {
    image:
      "https://i.ibb.co/GPfdj0q/Conex-o.png",
    title: "Conexão Paisagem",
    description:
      "Conexão Paisagem, lista as atrações turísticas de todos os estados e distrito federal do Brasil, onde é possível ver detalhes, informações e imagens de cada atração, além de ser possível compartilhar em suas redes sociais, com atualização constante de novas atrações.",
    technologys: [
      "React",
      "TypeScript",
      "Styled Components",
      "Polished",
      "React Icons",
      "Axios",
      "Fort Awesome",
      "Antd",
      "React Lottie"
    ],
    type: "full-stack",
    link: "https://search-attractions.vercel.app/",
  },
];

export const SocialMedias: SocialMedia[] = [
  {
    link: "https://github.com/HMontarroyos",
    name: faGithub,
  },
  {
    link: "https://www.npmjs.com/~hmontarroyos",
    name: faNpm,
  },
  {
    link: "https://www.linkedin.com/in/hebertmontarroyos-developer/",
    name: faLinkedin,
  },
  {
    link: "hebertmontarroyos@hotmail.com",
    name: faEnvelope,
  },
  {
    link: "https://www.holopin.io/@hmontarroyos#",
    name: faHeading,
  },
  {
    link: "https://codepen.io/hmontarroyos",
    name: faCodepen
  },
  {
    link: "https://dev.to/hmontarroyos",
    name: faDev,
  },
  {
    link: "https://medium.com/@hebertmontarroyos",
    name: faMedium,
  },
  {
    link: "https://www.instagram.com/hmontarroyosdev/",
    name: faInstagram,
  },
  {
    link: "https://twitter.com/HMontarroyosDev",
    name: faTwitter,
  },
  {
    link: "https://www.facebook.com/hmontarroyosdev",
    name: faFacebook,
  },
  { link: "https://www.quora.com/profile/Hebert-Montarroyos", name: faQuora },
];

export const jobs: Jobs[] = [
  {
    jobPosition: "Desenvolvedor de software",
    companyTime: "mar/2024 - até o momento",
    company: "Genialnet",
    jobDescription: ["Desenvolvedor Front-end."],
  },
  {
    jobPosition: "Analista de Sistemas",
    companyTime: "nov/2023 - fev/2024",
    company: "Nova Tendência",
    jobDescription: ["Desenvolvedor Front-end."],
  },
  {
    jobPosition: "Analista de Desenvolvimento",
    companyTime: "ago/2020 - mai/2023",
    company: "Calindra",
    jobDescription: [
      "Desenvolvedor Front-end.",
      "Auxiliava com tarefas no Back-end para integrar as interfaces com a lógica de negócio.",
      "Utilizava de frameworks e bibliotecas populares, como React no front e NodeJS no back -end.",
      "Integração de APIs de terceiros para fornecer recursos adicionais aos aplicativos.",
      "Otimização de desempenho de sites e aplicativos para garantir uma experiência do usuário ágil.",
    ],
  },
  {
    jobPosition: "Trainee em Tecnologia",
    companyTime: "fev/2020 - ago/2020",
    company: "Volanty Tecnologia E Servicos Veiculares",
    jobDescription: [
      "Programa de Desenvolvimento Full Stack.",
      "Abordado diversos tipos de tecnologias em diferentes Stacks tendo a vivência pratica e teórica.",
      "Algumas das Stacks abordadas : Front-end, Back-end, Mobile, Análise de dados entre outras",
    ],
  },
  {
    jobPosition: "Suporte Técnico",
    companyTime: "set/2018 - fev/2020",
    company: "Mls Wireless",
    jobDescription: [
      "Configuração de redes em ambiente windows e linux.",
      "Suporte técnico telefônico Abertura e retorno de chamados.",
      "Suporte técnico interno.",
    ],
  },
];

export const courses: Courses[] = [
  {
    institution: "Unyleya",
    graduation: "Engenharia de Software",
    timeTaken: "2020 - 2021",
    type: "Pós-graduação Lato Sensu",
  },
  {
    institution: "Centro Universitário UniDombosco",
    graduation: "Sistemas para Internet (com ênfase em Iot)",
    timeTaken: "2019 - 2020",
    type: "Graduação",
  },
  {
    institution: "Universidade Estácio de Sá",
    graduation: "Análise e Desenvolvimento de Sistemas",
    timeTaken: "2018 - 2020",
    type: "Graduação",
  },
  {
    institution: "Centro Universitário UniCarioca",
    graduation: "Técnico em Programação de Jogos Digitais",
    timeTaken: "2014 - 2015",
    type: "Curso Técnico",
  },
];

export const skills: Skills[] = [
  {
    name: "HTML5",
    icon: <FaHtml5 />,
    description:
      "Tenho mais de 6 anos de experiência trabalhando com HTML. Durante esse tempo, adquiri um profundo conhecimento dos principais conceitos e técnicas desta linguagem de marcação. Estou confortável com a criação e organização de elementos HTML, além de ter habilidades avançadas em áreas como formulários interativos, integração de multimídia, e também em conjunto com a estilização com CSS e otimização para mecanismos de busca (SEO).",
    link: "https://developer.mozilla.org/pt-BR/docs/Web/HTML",
    progress: 95,
  },
  {
    name: "JavaScript",
    icon: <SiJavascript />,
    description:
      "Utilizo de JavaScript já há pouco mais de 6 anos, tanto no front-end como no back-end e em outras stacks como no mobile e até já usei em sistemas embarcados com auxílio de libs. Sou proficiente em utilizar tanto no paradigma mais Funcional da linguagem como na Programação Orientada a Objetos, facilitando a organização eficiente de código. Domino a Manipulação do DOM, criando interfaces dinâmicas e responsivas, além de lidar com eventos do usuário. Tenho conhecimento em requisições assíncronas e atualizações precisas na página, como o uso de Promises e Async/Await, tornando operações assíncronas legíveis e eficazes. Entendo profundamente closures e escopo em JavaScript, garantindo funções encapsuladas e sólidas. Essa profunda compreensão da linguagem me permite criar aplicações web dinâmicas e sofisticadas, aplicando suas técnicas com eficiência.",
    link: "https://developer.mozilla.org/pt-BR/docs/Web/JavaScript",
    progress: 90,
  },
  {
    name: "CSS3",
    icon: <FaCss3Alt />,
    description:
      "Assim como a santíssima trindade do desenvolvimento web, não poderia deixar de fora o CSS, utilizo pouco mais de 6 anos para estilizar minhas paginas web. Domino a aplicação de estilos através da seleção de elementos HTML e classes, garantindo uma formatação consistente e elegante, além do conhecimento sobre os positions e nos demais displays. Aplico em muitos dos meus projetos os Pseudo-classes e Pseudo-elementos como o :hover, :active, :after e :before e muitos outros. Curto muito desenvolver transições e animações como pode ver no meu portfólio. Deixo totalmente responsivo a aplicação para diferentes tamanhos de tela, através dos media queries, assegurando uma experiência de usuário coesa em diversos dispositivos .",
    link: "https://developer.mozilla.org/pt-BR/docs/Web/CSS",
    progress: 85,
  },
  {
    name: "React",
    icon: <FaReact />,
    description:
      "Trabalho com o Desenvolvimento web já um certo tempo, em especial com React por um pouco mais de 4 anos, já sou familiarizado com as libs para a biblioteca a quais faço constante uso, utilizo dos 2 paradigmas dessa lib com Classes dos Components DidMount entre outros e funções com os hooks, useEffect e por ae vai, a quais utilizo hoje em dia mais nos meus projetos. Utilizo dos princípios de componetização para que cada pequena parte do meu projeto seja totalmente reutilizável em outro lugar da aplicação, assim como o uso de props para passar informações. Hoje em dia uns 85% das aplicações que desenvolvo web utilizo do React para trabalhar. ",
    link: "https://react.dev/",
    progress: 90,
  },
  {
    name: "TypeScript",
    icon: <SiTypescript />,
    description:
      "Possuo mais de 4 anos de experiência com TypeScript em meus projetos.  Utilizo do TypeScript a fim de deixar meu código, mas legível, oferecendo uma solução melhor como superset do JavaScript, deixando uma profunda compreensão da tipagem estática, o que facilita a detecção de erros durante o desenvolvimento. Utilizando de Interfaces e afins para deixar a tipagem explícita menos propenso a bugs. ",
    link: "https://www.typescriptlang.org/",
    progress: 75,
  },
  {
    name: "Styled Components",
    icon: <SiStyledcomponents />,
    description:
      "Utilizo o Styled Components na maioria dos meus projetos atualmente, inclusive utilizei dele para estilizar esse portfólio, ele é uma lib do React e do React Native que permite utilizar do famoso CSS-in-JS , que nada mais é do que criar estilização utilizando JavaScript com CSS. Vejo inúmeras vantagens de seu uso, como a facilidade para componetizar um tema global, separar suas variáveis de cores, fontes entre outras configurações em um arquivo de tema dele, além de conseguir estilizar outras libs com facilidade. Também aproveito a capacidade da lib de criar estilos com escopo local, evitando conflito e mantendo a separação de preocupações, outra coisa que curto muito nele é sua capacidade de criar estilos dinâmicos baseados em props ou estados de componentes React, possibilitando uma adaptação flexível às mudanças de estado.",
    link: "https://styled-components.com/",
    progress: 80,
  },
  {
    name: "Next",
    icon: <SiNextdotjs />,
    description:
      "Tenho experiência no uso do Next.js em meus projetos, já utilizei dele em algumas soluções que achei necessário, principalmente aproveitando os benefícios do Server-Side Rendering (SSR). Isso resultou em algumas aplicações notavelmente rápidas e menos dependentes do JavaScript no lado do cliente. Além disso, o Next.js simplificou algumas tarefas, complementando a eficiência do desenvolvimento, melhorando significativamente o desempenho, e a facilidade de uso para otimizar a experiência do usuário.",
    link: "https://nextjs.org/",
    progress: 60,
  },
  {
    name: "Redux",
    icon: <SiRedux />,
    description:
      "Já utilizei do Redux para gerenciar o estado de algumas aplicações. Ele proporcionou um controle mais efetivo sobre o estado global das aplicações que utilizei, facilitando a manipulação e sincronização dos dados entre os componentes. Além disso, contribuiu para a escalabilidade do projeto, permitindo lidar de forma organizada com aplicações de maior complexidade. Outro ponto forte é a capacidade de depurar e rastrear as mudanças no estado, o que facilitou a identificação e correção de possíveis bugs. Isso resultou em um desenvolvimento mais fluido e confiável ao longo do ciclo de vida do projeto.",
    link: "https://redux.js.org/",
    progress: 35,
  },
  {
    name: "Axios",
    icon: <SiAxios />,
    description:
      "Utilizo do Axios como meu cliente HTTP na maioria dos meus projetos, grande parte no front-end mas também já utilizei dele no back-end em node.  Já fiz uso de outros clientes http como o caso do próprio Fetch API nativo do JavaScript. Eu curto bastante trabalhar com o axios nas minhas chamadas por sua enorme facilidade de uso, além de que ele interpreta automaticamente respostas JSON, tornando o processo de lidar com dados de API mais conveniente, outra funcionalidade bem bacana é de ser possível configurar opções globais para todas as requisições Axios, como headers padrão ou baseURL. Além de também participar ativamente da comunidade como patrocinador dele no gihub colaborando com seu crescimento. ",
    link: "https://axios-http.com/",
    progress: 80,
  },
  {
    name: "Jest",
    icon: <SiJest />,
    description:
      "Já utilizei do Jest em projetos pessoais como projetos em empresas, atualmente costumo utilizar ele mais no back-end em node, porém, em algumas aplicações também fiz uso dele no front-end. Curto utilizar dele por sua simplicidade, além de que ele é super versátil e suporta tanto testes unitários (testes de unidades individuais de código) quanto testes de integração (testes que verificam a interação entre diferentes partes do sistema). Já fiz usos de outras libs também de testes com o MochaJS, Jasmine, SinonJs entre outras. Outro ponto bem bacana é que ele possui recursos robustos para mockar dependências, permitindo testar unidades de código de forma isolada.",
    link: "https://jestjs.io/pt-BR/",
    progress: 65,
  },
  {
    name: "NodeJS",
    icon: <DiNodejs />,
    description:
      "Utilizo do NodeJS para criar minhas APIs no back-end há mais de 4 anos já, escolhi utilizar dele por uma série de fatores, além de ele também ser escrito em JavaScript graças ao V8 do Chrome. Um dos melhores pontos dele e de ser conhecido por seu modelo de operações assíncronas e não bloqueantes, o que o torna eficiente em lidar com operações de I/O intensivas, além de que ele utiliza um sistema de módulos que facilita a organização e reutilização de código, podendo fazer uso do NPM, Yarn ou atém mesmo o PNPM para fazer o gerenciamento dos pacotes que oferece acesso a um vasto ecossistema de bibliotecas e ferramentas, graças a comunidade. Atualmente costumo utilizar de boas práticas, inclusive do SOLID para criar minhas APIs em NodeJS e também do MVC (Model-View-Controller) como padrão de projeto. Como eu trabalho com a Stack MERN (MongoDB, Express, React, NodeJS) não poderia deixar ele de fora, além dele ser altamente escalável devido à sua capacidade de manipular grandes volumes de conexões simultâneas com eficiência, uma ótima escolha para desenvolvimento de microserviços.",
    link: "https://nodejs.org/en",
    progress: 75,
  },
  {
    name: "Express",
    icon: <SiExpress />,
    description:
      "Faço o uso do Express.js parar criar minhas rotas para o NodeJS que facilita a criação das minhas APIs de forma rápida e eficiente. Ele utiliza middlewares para lidar com requisições HTTP. Isso facilita a execução de funções antes ou depois do processamento de uma requisição, tornando-o altamente modular, além de poder, lidar com diversos tipos de respostas, como HTML, JSON, XML, arquivos estáticos, entre outros. Sua documentação e sua simplicidade ajuda demais na hora de desenvolver. Não posso deixar ele de fora como parte da Stack MERN, a qual já citei anteriormente que faço uso em meus projetos full-stacks.",
    link: "https://expressjs.com/",
    progress: 75,
  },
  {
    name: "Mongo DB",
    icon: <SiMongodb />,
    description:
      "Como outro integrante da Stack MERN, se não um dos pilares dela, segue o MongoDB, o qual utilizo na grande maioria dos meus projetos como escolha de banco de dados, para guardas minhas informações na nuvem utilizo da solução dele, o Mongo Atlas, o qual é muito fácil graças a sua documentação e integrações com quase tudo. O MongoDB é um banco de dados NoSQL orientado a documentos, o que significa que ele armazena os dados em documentos flexíveis no formato BSON (Binary JSON). Isso permite uma modelagem de dados mais dinâmica e adaptável. Ele é projetado para facilitar a escalabilidade horizontal, o que significa que é capaz de lidar com grandes volumes de dados distribuindo-os em vários servidores, além de   suportar uma ampla gama de consultas, incluindo agregações, filtragens, ordenações e outras operações avançadas. Sem dúvidas uma das minhas melhores escolhas como banco de dados em meus projetos.",
    link: "https://www.mongodb.com/pt-br",
    progress: 75,
  },
  {
    name: "Docker",
    icon: <FaDocker />,
    description:
      "Conheço bem o Docker, já fiz uso em várias aplicações dos seus contêineres para não precisar instalar algo localmente. Ele isola aplicações e suas dependências em contêineres, proporcionando um ambiente consistente e independente de qualquer sistema operacional ou infraestrutura subjacente, isso facilita a criação de ambientes de desenvolvimento, teste e produção idênticos, garantindo que as aplicações funcionem consistentemente em diferentes estágios. Já cheguei também a trabalhar com orquestração de contêineres como o Docker Swarm e também já fiz uso do Kubernetes que permitem a orquestração e gerenciamento eficiente de múltiplos contêineres em ambientes de produção, subi também alguns contêineres para minha conta no docker hub o qual criei uns contêineres personalizados para minha aplicação e subir minha api dockerizada. O Docker Hub oferece um vasto repositório de imagens prontas para uso, o que acelera o processo de desenvolvimento e evita a necessidade de configurar ambientes do zero, o Docker também é amplamente suportado em pipelines de Integração Contínua/Desdobramento Contínuo (CI/CD), facilitando a automação de processos de build e deploy.",
    link: "https://www.docker.com/",
    progress: 45,
  },
  {
    name: "Aws",
    icon: <FaAws />,
    description:
      "Já fiz uso de alguns serviços dentre a gama de aplicações que a AWS fornece, algumas delas mais que outras, posso citar aqui, por exemplo, o bucket dela o Amazon S3 (Simple Storage Service), o qual uso frequentemente para poder guardar imagens, vídeos ou demais outros arquivos da minha api que não guardo por boa prática em meu banco de dados. O  S3 é um serviço de armazenamento de objetos altamente escalável, durável e seguro, além de ser muito simples trabalhar com ele. Outro serviço também que já utilizei em alguns projetos foi o Amazon DynamoDB para guardar os dados de uma aplicação de grande escala em que trabalhei, ele é um serviço de banco de dados NoSQL totalmente gerenciado, além de que ele oferece uma alta disponibilidade, escalabilidade automática e desempenho de leitura/escrita de latência baixa.",
    link: "https://aws.amazon.com/pt/",
    progress: 35,
  },
];

export const certificates: Certificates[] = [
  {
    title: "Bootcamp - LocalizaLabs React Developer",
    company: "DIO",
    date: "nov/2021",
    image:
      "https://hermes.digitalinnovation.one/certificates/cover/4BFD3B26.jpg",
    link: "https://www.dio.me/certificate/4BFD3B26/share",
  },
  {
    title: "Bootcamp - MRV Front End SPA Developer",
    company: "DIO",
    date: "out/2021",
    image:
      "https://hermes.digitalinnovation.one/certificates/cover/32428FC6.jpg",
    link: "https://www.dio.me/certificate/32428FC6",
  },
  {
    title: "Bootcamp - HTML Web Developer ",
    company: "DIO",
    date: "out/2020",
    image:
      "https://hermes.digitalinnovation.one/certificates/cover/D11AF983.jpg",
    link: "https://www.dio.me/certificate/D11AF983/share",
  },
];

export const awards: Awards[] = [
  {
    description:
      "Participei em 2015 do Projeto Rio Ideias +450, o qual fiquei entre as 30 primeiros colocados entre as  ideias de aplicativos para a cidade do Rio de Janeiro.",
  },
  {
    description:
      "Participei em 2023 da Rinha de Frontend organizada pela Codante, dentre mais de 400 participantes fui para as quartas de finais ficando entre os 20 primeiros da rinha.",
  }
];

export const linksNames: LinksNames[] = [
  {
    link: "home",
    name: "Início",
  },
  {
    link: "about",
    name: "Sobre",
  },
  {
    link: "experience",
    name: "Experiência",
  },
  {
    link: "project",
    name: "Projetos",
  },
  {
    link: "articles",
    name: "Artigos",
  },
  {
    link: "skills",
    name: "Habilidades",
  },
  {
    link: "certificates",
    name: "Licenças & Certificados",
  },
  {
    link: "awards",
    name: "Prêmios & Hackathons",
  },
];

export const occupationsNames: string[] = [
  "Software Engineer",
  "Fullstack Developer",
  "React",
  "Next",
  "JavaScript",
  "TypeScript",
  "Node.js",
];

export const softSkills: SoftSkills[] = [
  {
    name: "Líder",
    value: 80,
  },
  {
    name: "Produtivo",
    value: 95,
  },
  {
    name: "Proativo",
    value: 80,
  },
  {
    name: "Focado",
    value: 90,
  },
  {
    name: "Resiliente",
    value: 75,
  },
  {
    name: "Comunicativo",
    value: 70,
  },
  {
    name: "Participativo",
    value: 80,
  },
  {
    name: "Empático",
    value: 95,
  },
  {
    name: "Animado",
    value: 90,
  },
];
