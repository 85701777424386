import React from "react";
import * as S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { certificates } from "../../global/const";

const CarouselCertificates: React.FC<any> = ({}) => {
  return (
    <S.ContainerCarousel>
      <S.StyledCarousel autoplay>
        {certificates &&
          certificates.map((certificate, key) => (
            <S.Container key={key}>
              <S.Image
                src={certificate.image}
                alt={`imagem do certificado de ${certificate.title}`}
              />
              <S.Title>{certificate.title}</S.Title>
              <S.Description>{`${certificate.company} - ${certificate.date}`}</S.Description>
              <S.ContainerLink>
                <S.Link href={certificate.link} target="_blank">
                  Exibir credencial
                  <FontAwesomeIcon
                    style={{ marginLeft: "5px" }}
                    icon={faArrowUpRightFromSquare}
                  />
                </S.Link>
              </S.ContainerLink>
            </S.Container>
          ))}
      </S.StyledCarousel>
    </S.ContainerCarousel>
  );
};

export default CarouselCertificates;
