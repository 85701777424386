import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  z-index: 9999;
  position: fixed;
  width: 100%;
  top: 0;
`;

export const Header = styled.header`
  background-color: ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  height: 40px;
`;

export const ContainerLogo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 2rem;
  color: ${(props) => props.theme.colors.text};

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const LinkRedirect = styled(Link)`
  text-decoration: none;
`;

export const List = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const ItemList = styled.li`
  cursor: pointer;
  padding: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  transition: all 0.1s;
`;

export const Navigation = styled.nav`
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.fonts.title};
`;

export const ContainerHamburguer = styled.div`
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 2rem;
  color: ${(props) => props.theme.colors.text};
  display: none;
  position: fixed;
  right: 0;

  @media (max-width: 1000px) {
    display: inline-block;
  }

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const MobileMenu = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  width: 100%;
  position: absolute;
  z-index: 1000;

  @media (min-width: 1000px) {
    display: none;
  }
`;

export const ItemListHamburguer = styled.p`
  font-family: ${(props) => props.theme.fonts.title};
  cursor: pointer;
  padding: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  transition: all 0.1s;
`;
