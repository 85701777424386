import styled, { keyframes } from "styled-components";

const animationBorder = keyframes`
  0% {
    background-position:0;
  }
  50%, 100% {
    background-position: 100%;
  }
`;

export const Container = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 8px 32px 0 ${(props) => props.theme.colors.dark};
  margin: 30px;
  transition: background-color 0.3s, box-shadow 0.3s;

  @media (max-width: 870px) {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 10px;
  }

  &::before,
  &::after {
    border-radius: 10px;
    content: "";
    position: absolute;
    z-index: -1;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background: linear-gradient(
      85deg,
      ${(props) => props.theme.colors.background},
      ${(props) => props.theme.colors.primary},
      ${(props) => props.theme.colors.secondary},
      ${(props) => props.theme.colors.text}
    );
    background-size: 300%;
    animation: ${animationBorder} 2s linear alternate infinite;
  }

  &::after {
    filter: blur(50px);
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
    box-shadow: 0 0 5px ${(props) => props.theme.colors.secondary},
      0 0 25px ${(props) => props.theme.colors.secondary},
      0 0 50px ${(props) => props.theme.colors.secondary},
      0 0 100px ${(props) => props.theme.colors.secondary};
  }

  &:hover::before,
  &:hover::after {
    opacity: 0;
  }
`;

export const Image = styled.img`
  width: 15em;
  height: 295px;
  border-radius: 10px;
`;
