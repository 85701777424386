import React, { useEffect, useState } from "react";
import * as S from "./styled";
import { PerfilImage } from "../../assets/images/perfil";

interface ProfileProps {
  images: PerfilImage[];
}

const Profile: React.FC<ProfileProps> = ({ images }) => {
  const [image, setImage] = useState(images[0].src);

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * images.length);
      setImage(images[randomIndex].src);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [images]);

  return (
    <S.Container>
      <S.Image alt={"profile image"} src={image} />
    </S.Container>
  );
};

export default Profile;
