import styled, { keyframes } from "styled-components";

interface Props {
  progress: number;
}

export const AnimationProgress = keyframes`
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
`;

export const StyledProgressBar = styled.div`
  width: 300px;
  height: 12px;
  background-color: #d3d3d3;
  border: 1px solid #53cfd0;
  border-radius: 5px;

  @media (max-width: 520px) {
    width: 200px;
  }

  @media (max-width: 420px) {
    width: 130px;
  }
`;

export const FilledProgressBar = styled.div<Props>`
  background-size: 200% 200%;
  animation: ${AnimationProgress} 2s linear infinite alternate;
  background-image: linear-gradient(
    -45deg,
    ${(props) => props.theme.colors.tertiary},
    ${(props) => props.theme.colors.primary},
    ${(props) => props.theme.colors.secondary},
    ${(props) => props.theme.colors.text}
  );
  height: 100%;
  width: ${(props) => props.progress}%;
  background-color: #53cfd0;
  border-radius: 3px;
`;
