import React from "react";
import * as S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faNpm } from "@fortawesome/free-brands-svg-icons";
import { Project } from "../../global/const";
import Badge from "../Badge";

const CardProject: React.FC<Project> = ({
  image,
  type,
  title,
  description,
  technologys,
  link,
  github,
  npm,
}) => {
  return (
    <S.Container>
      <S.ContainerBadge>
        <Badge label={type} />
      </S.ContainerBadge>
      <S.Image src={image} mobile={type === "mobile"}/>
      <S.ContainerText>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
        {technologys &&
          technologys.map((technology, index) => (
            <Badge key={index} label={technology} circle />
          ))}
        <S.ContainerLink>
          <S.Link href={link} target="_blank">
            {`Link da aplicação ${title}`}
          </S.Link>
        </S.ContainerLink>
        <S.ContainerIcon>
          {npm && (
            <S.Link href={npm} target="_blank">
              <S.Icon>
                <FontAwesomeIcon icon={faNpm} />
              </S.Icon>
            </S.Link>
          )}
          {github && (
            <S.Link href={github} target="_blank">
              <S.Icon>
                <FontAwesomeIcon icon={faGithub} />
              </S.Icon>
            </S.Link>
          )}
        </S.ContainerIcon>
      </S.ContainerText>
    </S.Container>
  );
};

export default CardProject;
