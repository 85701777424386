import * as S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

interface StepperProps {
  data: any;
  icon: IconDefinition;
}

function Stepper({ data, icon }: StepperProps): JSX.Element {
  return (
    <S.ResumeTimeline>
      <S.TimelineWrap>
        {data &&
          data.map((d: any, key: string) => (
            <S.TimelineItem key={key}>
              <S.TimelineIcon>
                <S.Icon>
                  <FontAwesomeIcon icon={icon} />
                </S.Icon>
              </S.TimelineIcon>
              <S.ContainerJob>
                <S.ContainerJobPosition>
                  <S.Subtitle>{d.jobPosition || d.graduation}</S.Subtitle>
                  <S.Date>{d.companyTime || d.timeTaken}</S.Date>
                </S.ContainerJobPosition>
                <S.ContainerCompany>
                  <S.Company>{d.company || d.institution}</S.Company>
                  {d.jobDescription ? (
                    <S.List>
                      {d.jobDescription.map(
                        (jobDescription: any, key: string) => (
                          <S.ItemList key={key}>{jobDescription}</S.ItemList>
                        )
                      )}
                    </S.List>
                  ) : (
                    <S.Paragraph>{d.type}</S.Paragraph>
                  )}
                </S.ContainerCompany>
              </S.ContainerJob>
            </S.TimelineItem>
          ))}
      </S.TimelineWrap>
    </S.ResumeTimeline>
  );
}

export default Stepper;
