import styled from "styled-components";

export const ResumeTimeline = styled.div`
  max-width: 980px;
`;

export const TimelineWrap = styled.div`
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 6rem;

  &::before {
    content: "";
    display: block;
    width: 2px;
    height: 100%;
    background: ${(props) => props.theme.colors.text};
    position: absolute;
    left: 35%;
    top: 0;

    @media (max-width: 860px) {
      left: 15%;
    }
  }
`;

export const TimelineItem = styled.div`
  position: relative;
  padding-top: 1.5rem;
`;

export const TimelineIcon = styled.div`
  height: 4.8rem;
  width: 4.8rem;
  line-height: 4.8rem;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  text-align: center;
  color: ${(props) => props.theme.colors.text};
  position: absolute;
  left: 35%;
  top: 0.9rem;
  margin-left: -2.4rem;

  @media (max-width: 860px) {
    left: 15%;
  }
`;

export const Icon = styled.div`
  font-size: 2rem;
  color: white;

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const ContainerJob = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 860px) {
    display: block;
    text-align: right;
    margin-left: 100px;
  }
`;

export const ContainerJobPosition = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 20px;
  padding-right: 100px;
  width: 200px;

  @media (max-width: 860px) {
    width: 100px;
  }

  @media (max-width: 560px) {
    padding-right: 40px;
  }
`;

export const Subtitle = styled.h2`
  margin-bottom: 10px;
  color: white;
  font-family: ${(props) => props.theme.fonts.title};
  font-size: 24px;

  @media (max-width: 860px) {
    font-size: 20px;
  }
`;

export const Company = styled.h2`
  margin-left: 20px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.text};
  font-size: 35px;
  font-weight: 200;
  font-family: ${(props) => props.theme.fonts.title};

  @media (max-width: 860px) {
    font-size: 25px;
  }
`;

export const List = styled.ul`
  list-style-type: circle;
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.fonts.text};

  li {
    margin-left: 20px;
    margin-bottom: 10px;
  }
`;
export const ItemList = styled.li`
  margin-bottom: 10px;
  margin-left: 20px;
  margin-bottom: 10px;
`;

export const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.fonts.text};
  margin-left: 20px;
  margin-bottom: 10px;
`;

export const Date = styled.p`
  color: white;
  font-family: ${(props) => props.theme.fonts.text};
  font-size: 16px;

  @media (max-width: 860px) {
    margin-top: 5px;
    margin-bottom: 20px;
  }
`;

export const ContainerCompany = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  text-align: left;

  @media (max-width: 860px) {
    width: 300px;
  }

  @media (max-width: 560px) {
    width: 200px;
  }
`;
