import styled from "styled-components";
import { Carousel } from "antd";

interface Props {
  first?: boolean;
}

export const ContainerCarousel = styled.div`
  width: 500px;
  padding-bottom: 20px;

  @media (max-width: 500px) {
    width: 300px;
  }
`;

export const StyledCarousel = styled(Carousel)`
  width: 100%;
`;

export const Container = styled.div<Props>`
  background-color: ${(props) => props.theme.colors.primary};
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
`;

export const Title = styled.h1`
  margin-top: 10px;
  font-family: ${(props) => props.theme.fonts.title};
  font-size: 20px;
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 10px;

  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

export const Description = styled.p<Props>`
  font-family: ${(props) => props.theme.fonts.title};
  font-weight: 300;
  color: ${(props) => props.theme.colors.text};
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ContainerLink = styled.div`
  border-radius: 30px;
  background-color: ${(props) => props.theme.colors.quartenary};
  border: 1px solid ${(props) => props.theme.colors.text};
  display: flex;
  justify-content: center;
  width: 130px;
  padding: 5px;
  text-align: center;

  &:hover {
    border-color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Link = styled.a`
  font-family: ${(props) => props.theme.fonts.title};
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  color: ${(props) => props.theme.colors.text};
  &:hover {
    color: ${(props) => props.theme.colors.secondary};
    font-size: 15px;
  }
`;

export const Image = styled.img`
  width: 400px;
  height: 300px;

  @media (max-width: 500px) {
    width: 220px;
    height: 150px;
  }
`;

export const ContainerIcon = styled.div`
  margin-left: 10px;
  color: ${(props) => props.theme.colors.text};

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`;
