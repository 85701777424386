import React from "react";
import * as S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface CardSocialMedia {
  iconName: any;
  link: string;
  first?: boolean;
}

const CardSocialMedia: React.FC<CardSocialMedia> = ({
  iconName,
  link,
  first,
}) => {
  return (
    <S.Container first={first}>
      <S.Link
        href={link.includes("@hotmail.com") ? `mailto:${link}` : link}
        target="_blank"
      >
        <S.Icon>
          <FontAwesomeIcon icon={iconName} />
        </S.Icon>
      </S.Link>
    </S.Container>
  );
};

export default CardSocialMedia;
