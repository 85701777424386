import styled from "styled-components";

export const TypeExperience = styled.h2`
  color: ${(props) => props.theme.colors.text};
  font-size: 50px;
  margin-bottom: 30px;
  font-family: ${(props) => props.theme.fonts.title};
  font-weight: 300;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: 500px) {
    font-size: 35px;
  }
`;
