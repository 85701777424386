import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  margin-top: 30px;

  @media (max-width: 420px) {
    flex-direction: column;
  } 

`;

export const ContainerLink = styled.div`
 
`;

export const ContainerText = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 300px);

   @media (max-width: 520px) {
    width: calc(100% - 100px);
  }

  @media (max-width: 420px) {
    width: calc(100% - 30px);
  } 
`;

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.title};
  font-size: 20px;
  color: ${(props) => props.theme.colors.secondary};
  margin-bottom: 20px;
`;

export const Description = styled.p`
  margin-top: 10px;
  font-family: ${(props) => props.theme.fonts.title};
  font-weight: 300;
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 10px;
  line-height: 1.6;
  letter-spacing: 1px;

  @media (max-width: 420px) {
    margin-top: 30px;
  } 
`;

export const ContainerIcon = styled.div<{ showAllSkills: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 20px;
  /*  max-height: ${({ showAllSkills }) =>
    showAllSkills ? "400rem" : "30rem"}; */
  overflow: hidden;
  position: relative;
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const Icon = styled.div`
  cursor: pointer;
  margin-left: 20px;
  font-size: 8rem;
  color: white;
  display: inline-block;
  line-height: 8rem;

  &:hover {
    color: #53cfd0;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 50px;
`;
