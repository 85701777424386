import styled, { keyframes } from "styled-components";

export const AnimationGradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
`;

export const HeaderAnimation = styled.header`
  background-size: 200% 200%;
  animation: ${AnimationGradient} 2s linear infinite alternate;
  background-image: linear-gradient(
    -45deg,
    ${(props) => props.theme.colors.tertiary},
    ${(props) => props.theme.colors.primary},
    ${(props) => props.theme.colors.secondary},
    ${(props) => props.theme.colors.text}
  );
  width: 100%;
  height: ${(props) => props.theme.spacing.small};
`;
