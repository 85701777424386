import styled from "styled-components";
import { darken } from "polished";

interface Props {
  bold?: boolean;
}

export const Container = styled.div`
  color: ${(props) => props.theme.colors.text};
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.backgroundCard};
  box-shadow: 0 8px 32px 0 ${(props) => props.theme.colors.dark};
  margin: 10px;
  transition: background-color 0.3s, box-shadow 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => darken(0.2, props.theme.colors.secondary)};
    box-shadow: 0 0 5px ${(props) => props.theme.colors.secondary},
      0 0 25px ${(props) => props.theme.colors.secondary},
      0 0 50px ${(props) => props.theme.colors.secondary},
      0 0 100px ${(props) => props.theme.colors.secondary};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 500px) {
    display: block;
  }
`;

export const Image = styled.img`
  width: 250px;
  object-fit: cover;
  border-radius: 0 20px 20px 0;

  @media (max-width: 500px) {
    width: 100%;
    display: block;
    border-radius: 0 0 20px 20px;
  }
`;

export const ContainerText = styled.div`
  padding: 15px;
`;

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.title};
  font-size: 20px;
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 10px;
`;

export const Description = styled.p<Props>`
  font-family: ${(props) => props.theme.fonts.title};
  font-weight: ${(props) => (props.bold ? "bold" : "300")};
  color: ${(props) => props.theme.colors.text};
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
`;
