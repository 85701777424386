import { lazy } from "react";

const Home = lazy(async () => await import("./Home"));
const Start = lazy(async () => await import("./Start"));
const About = lazy(async () => await import("./About"));
const Experience = lazy(async () => await import("./Experience"));
const Project = lazy(async () => await import("./Project"));
const Articles = lazy(async () => await import("./Articles"));
const Skills = lazy(async () => await import("./Skills"));
const Certificates = lazy(async () => await import("./Certificates"));
const Awards = lazy(async () => await import("./Awards"));
const NotFound = lazy(async () => await import("./NotFound"));

export {
  Home,
  Start,
  About,
  Experience,
  Project,
  Articles,
  Skills,
  Certificates,
  Awards,
  NotFound
};
