import styled, { css } from "styled-components";

interface Props {
  first?: boolean;
}

export const Container = styled.div<Props>`
  width: 50px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  ${(props) =>
    !props.first &&
    css`
      margin-left: 10px;
    `};

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const Icon = styled.div`
  font-size: 2rem;
  color: ${(props) => props.theme.colors.text};

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`;
