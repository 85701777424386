import React from "react";
import * as S from "./styled";

interface ProgressBarProps {
  progress: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  return (
    <S.StyledProgressBar>
      <S.FilledProgressBar progress={progress} />
    </S.StyledProgressBar>
  );
};

export default ProgressBar;
