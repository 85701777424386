import styled, { css } from 'styled-components';

interface Props {
  svg: string;
  svgBottom: string;
  colorBackground: string;
  column?: boolean;
}

export const Container = styled.div<Props>`
  position: relative;
  background-color: ${(props) => props.colorBackground};
  display: flex;
  justify-content: space-around;
  margin-top: 150px;
  margin-bottom: 60px;
  padding-top: 20px;

  ${(props) =>
    props.column &&
    css`
      flex-direction: column;
      justify-content: flex-start; 
    `}

  @media (max-width: 410px) {
    flex-direction: column;
    justify-content: flex-start; 
  }

  &::before {
    content: "";
    background-image: url(${(props) => props.svg});
    background-size: 3549px 240px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    background-position: left;
    position: absolute;
    width: 100%;
    top: -88px;
    height: 92px;
  }

  &::after {
    content: "";
    background-image: url(${(props) => props.svgBottom});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    position: absolute;
    width: 100%;
    bottom: -88px;
    height: 92px;
    left: 0;
  }
`;
