import styled from "styled-components";

interface Props {
  circle?: boolean;
}

export const Badge = styled.div<Props>`
  display: inline-block;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: ${(props) => props.theme.colors.quartenary};
  color: ${(props) => props.theme.colors.secondary};
  border-radius: ${(props) => (props.circle ? "20px" : "0")};
  border: none;
  cursor: "text";
  margin-left: 5px;
  margin-top: 5px;
`;

export const Text = styled.p`
  padding: 0;
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.text};
`;
