import { useState } from "react";
import * as S from "./styled";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark, faCode } from "@fortawesome/free-solid-svg-icons";
import { linksNames } from "../../global/const";
import { LinkRedirect, HeaderAnimation } from "../../components";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const location = useLocation();

  const reloadPage = () => {
    if (location.pathname === "/") {
      window.location.reload();
    }
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <S.Container>
      <HeaderAnimation />
      <S.Header>
        <S.Navigation>
          <S.LinkRedirect to={"/"} onClick={reloadPage}>
            <S.ContainerLogo>
              <FontAwesomeIcon icon={faCode} />
            </S.ContainerLogo>
          </S.LinkRedirect>
          <S.List>
            <S.ItemList>
              {linksNames &&
                linksNames.map((link, key) => (
                  <LinkRedirect key={key} link={link.link} name={link.name} />
                ))}
            </S.ItemList>
          </S.List>
        </S.Navigation>
        <S.ContainerHamburguer onClick={toggleMenu}>
          <FontAwesomeIcon icon={!menuOpen ? faBars : faXmark} />
        </S.ContainerHamburguer>
      </S.Header>
      {menuOpen && (
        <S.MobileMenu>
          {linksNames &&
            linksNames.map((link, key) => (
              <S.ItemListHamburguer>
                <LinkRedirect key={key} link={link.link} name={link.name} />
              </S.ItemListHamburguer>
            ))}
        </S.MobileMenu>
      )}
    </S.Container>
  );
}

export default Header;
