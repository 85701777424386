import styled from "styled-components";

export const Container = styled.div`
  margin-top: 80px !important;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 30px;
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${(props) => props.theme.colors.text};
  font-size: 26px;
  font-family: ${(props) => props.theme.fonts.title};
  font-weight: 300;
  transition: color 0.3s, font-size 0.3s, font-weight 0.3s;

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
    font-weight: 400;
    font-size: 28px;
  }
`;
