import React, { ReactNode } from "react";
import * as S from "./styled";

interface ContainerProps {
  title: string;
  svg: string;
  svgBottom: string;
  colorBackground: string;
  column?: boolean;
  children: ReactNode;
}

const Container: React.FC<ContainerProps> = ({
  title,
  svg,
  svgBottom,
  colorBackground,
  column,
  children,
}) => {
  return (
    <S.Container
      id={title}
      svg={svg}
      svgBottom={svgBottom}
      colorBackground={colorBackground}
      column={column}
    >
      {children}
    </S.Container>
  );
};

export default Container;
