import React from "react";
import * as S from "./styled";
import { Zoom } from "react-reveal";

interface SubtitleProps {
  subtitle: string;
}

const Subtitle: React.FC<SubtitleProps> = ({ subtitle }) => {
  return (
    <Zoom top>
      <S.TypeExperience>{subtitle}</S.TypeExperience>
    </Zoom>
  );
};

export default Subtitle;
