import * as S from "./styled";

interface BadgeProps {
  label: string;
  circle?: boolean;
}

function Badge({ label, circle }: BadgeProps): JSX.Element {
  return (
    <S.Badge circle={circle}>
      <S.Text>{label}</S.Text>
    </S.Badge>
  );
}

export default Badge;
