import React, { MouseEvent, useEffect, useState } from "react";
import * as S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTurnDown, faTurnUp } from "@fortawesome/free-solid-svg-icons";
import { skills } from "../../global/const";
import { Button, ProgressBar } from "../../components";

interface HardSkillsProps {}

const HardSkills: React.FC<HardSkillsProps> = ({}) => {
  const [showAllSkills, setShowAllSkills] = useState(false);
  const [showMoreButtonText, setShowMoreButtonText] = useState("Ver mais");

  const toggleShowAllSkills = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowAllSkills((prevState) => !prevState);
    setShowMoreButtonText((prevText) =>
      prevText === "Ver mais" ? "Ver menos" : "Ver mais"
    );
    if (showMoreButtonText === "Ver menos") {
      const skillsContainer = document.getElementById("skills");
      if (skillsContainer) {
        window.scrollTo({
          top: skillsContainer.offsetTop,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <>
      <S.ContainerIcon showAllSkills={showAllSkills}>
        {skills
          .slice(0, showAllSkills ? skills.length : 5)
          .map((skill, key) => (
            <S.Container key={key}>
              <S.ContainerLink>
                <S.Link href={skill.link} target="_blank">
                  <S.Icon>{skill.icon}</S.Icon>
                </S.Link>
              </S.ContainerLink>
              <S.ContainerText>
                <S.Title>{skill.name}</S.Title>
                <ProgressBar progress={skill.progress} />
                <S.Description>{skill.description}</S.Description>
              </S.ContainerText>
            </S.Container>
          ))}
      </S.ContainerIcon>
      {skills.length > 5 && (
        <S.ContainerButton>
          <Button
            width="200px"
            height="40px"
            outline
            onClick={toggleShowAllSkills}
          >
            {showMoreButtonText}
            <FontAwesomeIcon
              icon={showMoreButtonText === "Ver mais" ? faTurnDown : faTurnUp}
              style={{ paddingLeft: "15px" }}
            />
          </Button>
        </S.ContainerButton>
      )}
    </>
  );
};

export default HardSkills;
